<template>
    <div id="collections">
      <v-responsive style="background-color: #000;">
        
        <v-img :src="'/img/home/gallery_header_01.jpeg'"
              :max-height="isMobileDevice ? 500 : 570"
              cover
              class="align-end"
              gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.2)"
        > 
          <v-card-title v-if="!isMobileDevice" style="color: #d8d8d8" class="text-h3 mb-4" >Viba Academy - Gallery</v-card-title>
          <v-card-title v-if="isMobileDevice" style="color: #d8d8d8" class="text-h6 mb-4" >Viba Academy - Gallery</v-card-title>

        </v-img>
  
        <v-card theme="dark" color="#383838">

            <v-row class="pt-8 mb-16">
                <v-col :align="'center'" cols="12" md="4" v-for="(item, index) in videos" :key="index" >
                    <iframe width="300" height="540" :key="index" 
                            :src="item.url" 
                            :title="item.title" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                    >
                    </iframe>
                </v-col>
            </v-row>
  
        </v-card>
    
      </v-responsive>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { scroller } from 'vue-scrollto/src/scrollTo'
  // import ExclusiveNFTsFiltered from './collections/ExclusiveNFTsFiltered'
  // import GamesInsightsFiltered from './collections/GamesInsightsFiltered'
  export default {
    name: 'Collections',
    props: {
      isMobileDevice: Boolean,
      dark: Boolean,
      windowWidth: Number,
      windowHeight: Number,
      drawer: Boolean
    },
    data: () => ({
      loading: false,
      tab: 0,
      view: 0,
      videos: [
        // { title:'Unleash Your Inner Champion at Viba Academy BJJ! #VibaBJJChampions', url:'https://www.youtube.com/embed/VpHwOndOV_U' },
        { title: 'Post 1: 🥋 &quot;The Truth Unmasked! 🥋 BJJ Empowerment: Ignite Your Inner Fire  | BJJ Xcel', url: 'https://www.youtube.com/embed/XJ-FlrWm6cA'},
        { title:'Post 8:💡 &quot;The Power of Genuine BJJ 💡 Empowerment in Grappling: BJJ Uncovered🌟 @BJJXcel', url:'https://www.youtube.com/embed/jpzVArNJE8s' },
        { title:'Post 9: 🔎 &quot;Debunking BJJ Myths 🔎 BJJ Empowerment Demystified: Women&#39;s Unbeatable Force 🥋 @BJJXcel', url:'https://www.youtube.com/embed/8k8c55r9yDM' },
        { title:'Post 10:🌐 &quot;The Viba Academy Revelation 🌐 BJJ Empowerment for All: Breaking Barriers @BJJXcel', url:'https://www.youtube.com/embed/vK5xanCuprg' },
        { title:'Post 11:🌟 Celebrating BJJ Excellence 🌟 The Empowering Art of BJJ: Master Your Mind', url:'https://www.youtube.com/embed/NxfP2QJF7us' },
        { title:'Post 12:💥 Embrace the Power of BJJ Women 💥BJJ Empowerment Transcendence Find Your Balance @BJJXcel', url:'https://www.youtube.com/embed/Mt2x3-wa2hY' }
      ]
    }), 
    components: {
      // ExclusiveNFTsFiltered,
      // GamesInsightsFiltered
    },
    computed: {
    
    },
    watch: {
    },
    created() {
      // this.currentUser = firebase.auth().currentUser;
      this.scrollToTop()
      this.init()
    },
    methods: {
      init () {
       // this.openExclusiveCollection(4)
      },
      scrollToTop () {
        const firstScrollTo = scroller();
        this.scrollClicked = true
        setTimeout(() => {
          firstScrollTo('#collections', 500, { offset: -64 });
        }, 200);
      },
    }
  }
  </script>
  
  <style lang="scss">
    
  </style>
  